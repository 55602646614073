<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="period">Periode</label>
                                <Calendar selectionMode="range" v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="se_id" @sort="onSort($event)" :resizableColumns="true">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            {{slotProps.data.username}}
                        </template>
                    </Column>
                    <Column field="name" header="Nama">
                        <template #body="slotProps">
                            {{slotProps.data.name}}
                        </template>
                    </Column>
                    <Column field="tanggal" header="Tanggal">
                        <template #body="slotProps">
                            {{slotProps.data.tanggal}}
                        </template>
                    </Column>
                    <Column field="value_tanggal" header="Status Tanggal">
                        <template #body="slotProps">
                            {{slotProps.data.value_tanggal}}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            
            // filter
            period: [new Date(), new Date()],
            maxDate: null,
            filters: {
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){

            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  

                this.loading=true;
                this.page = (this.offset / this.rows) + 1;

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/report-absensi',
                    params: {
                        "search" : this.search,
                        "per_page" : this.rows,
                        "page" : this.page,
                        "order_by" : this.field,
                        "sort_by" : this.sort,
                        "start_date" : this.period_start_label,
                        "end_date" : this.period_end_label,
                    }
                })
                .then(res => {
                    this.data = res.data.data.data;
                    this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                    this.totalItemsCount = res.data.data.total;
                    this.rows = parseInt(res.data.data.per_page);
                    this.loading=false;
                })
                .catch((err) => {
                    console.log(err);
                });
            }else{
                alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }

        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  


                if(ext == 'xlsx'){
                    this.loadingExcel=true;
                }else if(ext == 'csv'){
                    this.loadingCsv=true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/report-absensi/export',
                    responseType: 'blob',
                    params: {
                        "ext" : ext,
                        "start_date" : this.period_start_label,
                        "end_date" : this.period_end_label,
                    }
                })
                .then(response => {
                    let fileUrl = window.URL.createObjectURL(response.data);
                    let fileLink = document.createElement('a');

                    fileLink.href = fileUrl;

                    fileLink.setAttribute('download', 'Report Absensi Export.' + ext);
                    
                    document.body.appendChild(fileLink)

                    fileLink.click();
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }

                })
                .catch((err) => {
                    console.log(err);
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }
                });
            }else{
                alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        },
    }
}
</script>